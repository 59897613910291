import * as React from 'react'
import Layout from '../components/layout'
import '../styles/global.css'
import {Helmet} from "react-helmet";


export default function Approach() {
    return (
      <Layout pageTitle="Approach">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>Our Approach</title>
        <meta name="description" content="We use a simple but effective philosophy and methodology across all of our projects. This enables us to deliver design-led innovation we can measure."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>

        <link rel="apple-touch-icon" sizes="180x180" href="favicon/apple-touch-icon.png"/>
        <link rel="icon" type="image/png" sizes="32x32" href="favicon/favicon-32x32.png"/>
        <link rel="icon" type="image/png" sizes="16x16" href="favicon/favicon-16x16.png"/>
        <link rel="manifest" href="favicon/site.webmanifest"/>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"/>
      </Helmet>

   <div className="bg-pink-900 md:p-8 top">
        <div className="container w-full md:w-1/2 p-5 sm:pl-12 pb-16 pt-16 lg:pt-24">
          <h2 className="text-7xl text-white font-heading leading-tight">
            Our Approach</h2>
          <br/>

          <p className="text-2xl text-white pt-9">We use a simple but effective philosophy and methodology across
            all of our projects. This enables us to deliver design-led innovation we can measure.</p>
        </div>
      </div>





    <div className="md:p-8 pl-3 pr-3 md:pt-16 md:pb-40 top">
      <div className="w-full md:w-4/5 4xl:w-2/3 p-5 sm:pl-12">
        <h1 className="leading-tight text-2xl font-semibold md:text-6xl pt-9">We believe the
          best innovation happens at the intersection between people, place, and&nbsp;purpose.</h1>
      </div>

      <div className="sm:pl-12 pb-16">
        <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0">
          <div className="w-full lg:w-1/2 px-4 mb-4 md:mb-0">


            <h1 
              className="leading-tight text-2xl md:text-4xl p-6 pt-9 sm:p-0 sm:pt-9">People</h1>
            <p data-scroll data-scroll-speed="1"
              className="text-xl md:text-2xl md:pt-9 p-6 pt-1 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
              We believe public spaces should be for people. We work with key stakeholders and engage them throughout
              the research, design and delivery process to ensure our visions have public buy-in and are rooted in local
              culture and relevant to local people.
            </p>

            <h1 className="leading-tight text-2xl md:text-4xl p-6 pt-9  sm:p-0 sm:pt-9">Place</h1>
            <p className="text-xl md:text-2xl md:pt-9 p-6 pt-1 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
              We ensure ideas are rooted in the history and heritage of the place. This includes understanding how
              concepts and strategies work in harmony with other initiatives, sites and constraints.

            </p>

            <h1 className="leading-tight text-2xl md:text-4xl p-6 pt-9 sm:p-0 sm:pt-9">Purpose</h1>
            <p className="text-xl md:text-2xl md:pt-9 p-6 pt-1 md:pl-0 md:pr-0 md:pb-0  md:mr-20">
              We deliver projects with public value. Everything we do is rooted in social impact to create better places
              for people.
            </p>
          </div>


          <div className="w-full lg:w-1/2 px-4 mb-4 md:mb-0 pt-6 md:pt-0">
            <img src="img/approach/peopleplacepurpose.svg" alt="people place purpose"/>
          </div>
        </div>
      </div>
    </div>

    <img className="w-full" src="img/approach/culture-shop.png" alt="belfast culture shop"/>


    <div className="md:pt-20 md:mt-12 md:mb-20 md:mb-20">




      <div className="md:p-8 pl-3 pr-3 pt-5 pb-5 top">

        <div className="sm:pl-12 pb-16">
          <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0">
            <div className="w-full lg:w-3/5 px-4 mb-4 md:mb-0">
              <p className="text-xl md:text-2xl p-6 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
                We use the established double-diamond design methodology across all of our projects. This approach to
                innovation was first coined by the UK Design Council in 2005 </p>
            </div>
          </div>


        </div>
      </div>

      <img className="w-full hidden sm:block" src="img/approach/double-diamond.svg" alt="double diamond"/>
      <img className="w-full sm:hidden" src="img/approach/double-diamond-mobile.svg" alt="double diamond"/>

      <div className="md:pl-8 md:pr-8 md:pb-8 md:pt-32 pl-3 pr-3 pt-4 pb-5 top">

        <div className="sm:pl-12 pb-16">
          <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0">
            <div className="w-full lg:w-4/5 px-4 mb-4 md:mb-0">
              <h1 className="leading-tight text-2xl font-bold md:font-normal md:text-6xl pt-9">This approach has led
                us to become founding members of several innovative international networks </h1>
            </div>
          </div>
        </div>


        <div className="sm:pl-12 pb-16">
          <div className="flex flex-wrap -mx-4 -mb-4 md:mb-0">
            <div className="w-full lg:w-1/3 px-4 mb-4 md:mb-0">
              <img src="img/approach/logo1.png" alt="safer public space logo"/>
              <p className="text-xl md:text-2xl p-6 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
                A global network of organisations aiming to make our public spaces inclusive and
                safe.</p>
              <a href="https://saferpublicspace.org/" target="_blank"
                className="font-semibold w-4/5 sm:w-full arrow mb-12 ml-6 sm:mb-3 sm:ml-0 sm:mt-4 text-black hover:text-black ease-in-out text-xl md:text-2xl">Safer
                Public Space
                <span></span>
              </a>
            </div>

            <div class="w-full lg:w-1/3 px-4 mb-4 md:mb-0">
              <img data-scroll data-scroll-speed="1" src="img/approach/logo2.png" alt="culture next logo"/>
              <p data-scroll data-scroll-speed="1"
                class="text-xl md:text-2xl p-6 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
                Over 23 member cities in Europe developing cultural-led urban development programmes.</p>
              <a data-scroll data-scroll-speed="1" href="https://culturenext.eu/" target="_blank"
                class="font-semibold w-4/5 sm:w-full arrow mb-12 ml-6 sm:mb-3 sm:ml-0 sm:mt-4 text-black hover:text-black ease-in-out text-xl md:text-2xl">Culture
                Next
                <span></span>
              </a>
            </div>

            <div class="w-full lg:w-1/3 px-4 mb-4 md:mb-0">
              <img className="" src="https://ecrn.city/wp-content/themes/ecrn/img/ecrn-logo-trans.svg" alt="european rooftops logo"/>
              <p 
                class="text-xl md:text-2xl p-6 md:pl-0 md:pr-0 md:pb-0 md:mr-20">
                Creative Europe programme developing rooftop strategies and interventions across 9 EU cities.
              </p>
              <a href="https://rotterdamsedakendagen.nl/en/european-rooftop-network/"
                target="_blank"
                class="font-semibold w-4/5 sm:w-full arrow mb-12 ml-6 sm:mb-3 sm:ml-0 sm:mt-4 text-black  hover:text-black ease-in-out text-xl md:text-2xl">Rooftops
                For Europe
                <span></span>
              </a>
            </div>
          </div>
        </div>
      </div>


    </div>
  
      </Layout>
    )
  }
  